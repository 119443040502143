<template>
  <div id="app-layout">           
   <div class="home__title">
              <h2 class="home__title__bg">MENTIONS LEGALES</h2>
            </div>         
            <div class="pagecontent">
              <div class="legal">
                  <h3 class="legal__title">1. Office</h3>
                  <p class="legal__text">Office notarial de M<sup>e</sup> Angélique Montagner
                                        <br>12 Avenue de Laubian, 40510 Seignosse
                                        <br><a href="mailto:officedelaubian.seignosse@notaires.fr">officedelaubian.seignosse@notaires.fr</a>
                                        <br><a href="tel:+33558583515">05 58 58 35 15</a>
                                        <br>Numéro SIRET : 84418011700013
                                        <br>Numéro TVA Intracommunautaire : FR52844180117
                                        <br>Numéro d'agrément : 40058210430113</p>
                  <h3 class="legal__title">2. Editeur</h3>
                  <p class="legal__text">EIRL LM Créations Numériques
                                        <br>Lieu dit Paussis, 82500 Esparsac
                                        <br>contact@lmcreationsnumeriques.fr
                                        <br><a href="https://lmcreationsnumeriques.fr">lmcreationsnumeriques.fr</a>
                                        <br>N° de SIRET : 89748949800013
                                        <br>Numéro de TVA intracommunautaire : FR27897489498
                                        <br>Responsable de la publication : M<sup>e</sup> Angélique Montagner.</p>
                  <h3 class="legal__title">3. Hébergeur</h3>
                  <p class="legal__text">OVH SAS
                                        <br>SAS au capital de 10 069 020 €
                                        <br>RCS Lille Métropole 424 761 419 00045
                                        <br>Code APE 2620Z
                                        <br>N° TVA : FR 22 424 761 419
                                        <br>Siège social : 2 rue Kellermann - 59100 Roubaix - France
                  </p>
                </div>  
            </div>           
  </div>
</template>

<script>
  export default {
    metaInfo: {
    // Children can override the title.
    title: 'Mentions Légales - Office Notarial de Me Montagner',
    link: [
    {rel: 'canonical', href: 'https://angelique-montagner.notaires.fr/Mentionslegales'}
   ]
  },
    name: "mentionslegales"
  }
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.sitemap {
    padding: 50px;
    &__sous {
        margin-left: 50px;
    }
}

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

.legal {
  width: 55%;
  margin: auto;
  text-align: center;
   @media screen and (max-width: 979px){
        width: 100%;
    }
  &__title {
    margin-bottom: 15px;
  }
  &__text {
    margin-bottom: 30px;
  }
}
</style>